import { Component, ErrorInfo, ReactNode } from "react"

import RestoreIcon from "@mui/icons-material/Restore"
import Alert from "@mui/material/Alert"
import AlertTitle from "@mui/material/AlertTitle"
import Button from "@mui/material/Button"

import ErrorBoundaryIllustration from "@/assets/svg/error-boundary.svg"
import { t } from "@/hooks"

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
  error: null | Error;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    error: null
  }

  public static getDerivedStateFromError (errors: Error): State {
    // Update state so the next render will show the fallback UI.
    return {
      hasError: true,
      error: errors
    }
  }

  public componentDidCatch (error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo)
  }

  public render () {
    if (this.state.hasError) {
      return (
        <section className="flex flex-col items-center gap-4">
          <img
            alt={t("errorBoundaryImageAlt")}
            src={ErrorBoundaryIllustration}
            width="640"
          />
          <Alert
            severity="error"
            variant="filled"
          >
            <AlertTitle>
              {this.state.error?.name ?? t("errorsFound")}:
            </AlertTitle>
            <p className="mb-2">
              <pre className="mb-2 whitespace-pre-wrap break-words">
                {this.state.error?.message}{!this.state.error?.message.endsWith(".") && "."}
              </pre>
              {t("errorBoundaryMessage")}
            </p>
            <Button
              color="error"
              size="small"
              startIcon={<RestoreIcon />}
              variant="contained"
              sx={{
                border: "1px solid #FFFFFF"
              }}
              onClick={() => location.reload()}
            >
              {t("reload")}
            </Button>
          </Alert>
        </section>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
