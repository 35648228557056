import { lazy } from "react"

import { ADMIN, HUMAS, OPERATOR, SUPER_ADMIN } from "@/config/user"

const AdminPanelSettingsOutlinedIcon = lazy(() => import("@mui/icons-material/AdminPanelSettingsOutlined"))
const AssignmentIndOutlinedIcon = lazy(() => import("@mui/icons-material/AssignmentIndOutlined"))
const AssignmentOutlinedIcon = lazy(() => import("@mui/icons-material/AssignmentOutlined"))
const GroupsOutlinedIcon = lazy(() => import("@mui/icons-material/GroupsOutlined"))
const HexagonOutlinedIcon = lazy(() => import("@mui/icons-material/HexagonOutlined"))
const HistoryIcon = lazy(() => import("@mui/icons-material/History"))
const HomeOutlinedIcon = lazy(() => import("@mui/icons-material/HomeOutlined"))
const InterestsOutlinedIcon = lazy(() => import("@mui/icons-material/InterestsOutlined"))
const MailOutlinedIcon = lazy(() => import("@mui/icons-material/MailOutlined"))

interface SidebarMenuItems {
  label: string
  path: string
  roles: number[]
  icon: React.ReactNode
}

export const MENU: Array<SidebarMenuItems> = [
  {
    label: "Beranda",
    path: "/",
    roles: [SUPER_ADMIN, ADMIN, OPERATOR, HUMAS],
    icon: <HomeOutlinedIcon />
  },
  {
    label: "Kuesioner",
    path: "/kuesioner",
    roles: [SUPER_ADMIN, ADMIN, OPERATOR],
    icon: <AssignmentOutlinedIcon />
  },
  {
    label: "Riwayat Aktivitas",
    path: "/riwayat-aktivitas",
    roles: [SUPER_ADMIN, ADMIN],
    icon: <HistoryIcon />
  },
  {
    label: "Aduan",
    path: "/aduan",
    roles: [SUPER_ADMIN, HUMAS],
    icon: <MailOutlinedIcon />
  },
  {
    label: "Pengguna",
    path: "/pengguna",
    roles: [SUPER_ADMIN, ADMIN],
    icon: <GroupsOutlinedIcon />
  },
  {
    label: "Tenaga Ahli",
    path: "/tenaga-ahli",
    roles: [SUPER_ADMIN, ADMIN],
    icon: <AssignmentIndOutlinedIcon />
  },
  {
    label: "Admin",
    path: "/admin",
    roles: [SUPER_ADMIN],
    icon: <AdminPanelSettingsOutlinedIcon />
  },
  {
    label: "Master Data Klaster",
    path: "/klaster",
    roles: [SUPER_ADMIN, ADMIN],
    icon: <InterestsOutlinedIcon />
  },
  {
    label: "Master Data Indikator",
    path: "/indikator",
    roles: [SUPER_ADMIN, ADMIN],
    icon: <HexagonOutlinedIcon />
  }
]
