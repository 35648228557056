export const indonesian = {
  // BUTTON & ACTION
  add: "Tambah",
  apply: "Terapkan",
  back: "Kembali",
  cancel: "Batal",
  close: "Tutup",
  save: "Simpan",
  send: "Kirim",
  delete: "Hapus",
  edit: "Edit",
  view: "Lihat",
  addOption: "Tambahkan opsi",
  addFeedback: "Beri Tanggapan",
  reload: "Muat Ulang",
  reset: "Reset",
  filter: "Filter",
  activate: "Aktivasi",
  deactivate: "Nonaktifkan",
  changeStatus: "Ubah status",
  review: "Tinjau",
  evaluate: "Evaluasi",
  period: "Periode",
  logout: "Keluar",

  // PAIR WORDS
  active: "Aktif",
  inactive: "Tidak Aktif",

  // COMMONS
  welcome: "Selamat datang",
  pleaseWait: "Mohon tunggu...",
  sending: "Mengirim...",
  saving: "Menyimpan...",
  at: "pada",
  category: "Kategori",
  sender: "Pengirim",
  year: "Tahun",
  chooseOne: "Pilih salah satu",
  noOptions: "Opsi tidak tersedia",
  noData: "Tidak ada data",
  seeOptions: "Lihat opsi",

  // GLOBAL ERROR MESSAGE
  errorsFound: "Terjadi galat tak terduga:",
  incompatibleDeviceMessage: "Harap perbarui perangkat Anda. Skala Dashboard membutuhkan perangkat dengan minimal lebar layar 1280px.",
  noInternetMessage: "Perangkat Anda tidak terhubung internet. Mohon periksa koneksi Anda.",
  noServerConnection: "Gagal menghubungkan perangkat Anda ke server. Mohon coba ulangi beberapa saat lagi.",
  notFoundMessage: "Mohon maaf, halaman yang Anda cari tidak dapat kami temukan.",
  serverErrorMessage: "Terjadi kesalahan pada server. Mohon coba lagi nanti.",
  errorBoundaryMessage: "Terjadi galat tak terduga saat memuat halaman. Mohon hubungi admin untuk memperbaiki masalah ini. Silakan muat ulang halaman atau coba kunjungi lagi halaman ini nanti. Jika Anda developer, harap periksa konsol untuk melihat penyebab terjadinya galat.",

  // IMAGE ALT
  notFoundImageAlt: "Ilustrasi Halaman Tidak Ditemukan",
  errorBoundaryImageAlt: "Aplikasi Error",
  homepageImageAlt: "Ilustrasi Beranda",

  // HOME
  dashboard: "Dasbor",
  home: "Beranda",

  logoutConfirmation: "Apakah Anda yakin ingin keluar?",
  failedLogout: "Terjadi kesalahan saat mencoba logout. Mohon coba ulangi beberapa saat lagi!",

  // CLUSTER
  cluster: "Klaster",
  createCluster: "Buat Klaster",
  editCluster: "Edit Klaster",
  detailCluster: "Detail Klaster",
  deleteCluster: "Hapus Klaster",
  deleteClusterConfirmation: "Apakah Anda yakin ingin menghapus klaster ini?",
  clusterNameInputLabel: "Nama Klaster",
  clusterDescriptionInputLabel: "Deskripsi Klaster",
  clusterIndexInputLabel: "Indeks Klaster",
  clusterStatusInputLabel: "Status Klaster",
  successCreateClusterMessage: "Berhasil membuat klaster baru!",
  successUpdateClusterMessage: "Berhasil memperbarui data klaster!",
  successDeleteClusterMessage: "Berhasil menghapus klaster!",

  // INDICATOR
  indicator: "Indikator",
  createIndicator: "Buat Indikator",
  editIndicator: "Edit Indikator",
  detailIndicator: "Detail Indikator",
  deleteIndicator: "Hapus Indikator",
  deleteIndicatorConfirmation: "Apakah anda yakin ingin menghapus indikator ini?",
  indicatorNameInputLabel: "Nama Indikator",
  indicatorParameterInputLabel: "Ukuran Indikator",
  indicatorStatusInputLabel: "Status Indikator",

  // FEEDBACK
  feedbackCategory: "Kategori Aduan",
  feedbackCategoryNameFormTitle: "Buat Kategori",
  feedbackCategoryNameLabel: "Nama Kategori",
  successCreateFeedbackCategoryMessage: "Berhasil menambahkan kategori",
  successDeleteFeedbackCategoryMessage: "Berhasil menghapus kategori",
  successAnswerFeedbackMessage: "Terima kasih! Tanggapan telah dikirim!",
  failedCreateFeedbackCategoryMessage: "Server gagal menambahkan kategori. Mohon coba ulangi beberapa saat lagi!",
  failedDeleteFeedbackCategoryMessage: "Server gagal menghapus kategori. Mohon coba ulangi beberapa saat lagi!",
  failedAnswerFeedbackMessage: "Maaf, server gagal mengirim tanggapan Anda. Mohon coba ulangi beberapa saat lagi!",
  failedGetFeedbackSender: "Nama pengirim tidak ditemukan",
  feedbackReply: "Beri Tanggapan",
  feedbackSendBy: "Dikirim oleh",
  feedbackOverviewTitle: "Ikhtisar",
  feedbackTitleInputLabel: "Judul",
  feedbackMessageInputLabel: "Pesan",

  // QUESTIONNAIRE
  createQuestionnaire: "Buat Kuesioner",
  questionnaireDescriptionInputLabel: "Deskripsi Kuesioner",
  questionnaireLevelInputLabel: "Tingkat",
  questionTypeInputLabel: "Tipe Pertanyaan",
  question: "Pertanyaan",
  lastUpdated: "Terakhir Diperbarui",
  needAttachment: "Perlu Lampiran",
  successCreateQuestionnaireMessage: "Berhasil membuat kuesioner baru!",
  successUpdateQuestionnaireRangeMessage: "Berhasil memperbarui data tanggal pengisian kuesioner!",
  successUpdateQuestionnaireStatusMessage: "Berhasil memperbarui status kuesioner!",
  successSaveQuestionnaireAnswer: "Jawaban Anda berhasil tersimpan!",
  failedSaveQuestionnaireAnswer: "Mohon maaf kami gagal menyimpan jawaban Anda. Mohon coba ulangi beberapa saat lagi!",
  defineQuestionnaireRange: "Periode Evaluasi",
  startRange: "Mulai",
  endRange: "Selesai",
  questionnaireNotInRange: "Sedang tidak dalam tanggal pengisian kuesioner",
  questionnaireTextFieldPlaceholder: "Jawaban Anda",
  questionnaireFileInputPlaceholder: "Unggah Lampiran",
  questionnaireFileInputHelper: "Anda dapat melampirkan maksimal 5 berkas dengan ukuran tiap berkas maksimal 2 MB!",
  questionnaireFileInputExceedFileHelper: "Mohon maaf, Anda hanya dapat mengunggah maksimal 5 berkas!",
  questionnaireFileInputExceedFileSizeHelper: "memiliki ukuran berkas lebih dari 2 MB!",
  operatorQuestionnaireNotFound: "Belum ada kuesioner untuk",
  backToQuestionnaireListModalTitle: "Anda ingin keluar dari halaman ini?",
  backToQuestionnaireListModalMessage: "Mohon pastikan Anda telah klik tombol simpan sebelum melanjutkan untuk keluar dari halaman ini.",
  changeQuestionnaireStatusConfirmation: "Apakah Anda yakin ingin mengubah status kuesioner ini?",

  // CONSULTATION
  consultation: "Konsultasi",

  // USER
  personalInformationFormTitle: "Data diri",
  personalInformationInputNameLabel: "Nama",
  personalInformationInputIDNumberLabel: "NIK",
  personalInformationInputGenderLabel: "Jenis Kelamin",
  personalInformationInputBirthdateLabel: "Tanggal Lahir",
  personalInformationInputImageLabel: "Foto",
  personalInformationInputDescriptionLabel: "Deskripsi",
  personalInformationInputNamePlaceholder: "Putra Nusantara",
  personalInformationInputIDNumberPlaceholder: "2245061501010001",
  personalInformationInputImagePlaceholder: "Unggah Foto",
  personalInformationInputDescriptionPlaceholder: "Deskripsi diri",
  personalInformationInputBirthdatePlaceholder: "17 Agustus 1945",

  educationInformationFormTitle: "Pendidikan",
  educationInformationInputSchoolLabel: "Sekolah/Universitas",
  educationInformationInputDepartmentLabel: "Jurusan/Program Studi",
  educationInformationInputGraduationYearLabel: "Tahun Lulus",
  educationInformationInputSchoolPlaceholder: "Universitas Sebelas Maret",
  educationInformationInputDepartmentPlaceholder: "Kedokteran",
  educationInformationInputGraduationYearRequired: "Mohon isi Tahun Kelulusan",
  educationInformationInputGraduationYearInvalidYear: "Mohon isi dengan tahun kelulusan",

  contactInformationFormTitle: "Informasi Kontak",
  contactInformationAddressFormTitle: "Alamat",
  contactInformationInputPhoneLabel: "Nomor Telepon",
  contactInformationInputEmailLabel: "Email",
  contactInformationInputProvinceLabel: "Provinsi",
  contactInformationInputRegencyLabel: "Kabupaten/Kota",
  contactInformationInputDistrictLabel: "Kecamatan",
  contactInformationInputSubDistrictLabel: "Kelurahan",
  contactInformationInputPhonePlaceholder: "851 6285 6285",
  contactInformationInputEmailPlaceholder: "pemuda@indonesia.id",
  contactInformationInputProvincePlaceholder: "Pilih provinsi...",
  contactInformationInputRegencyPlaceholder: "Pilih kabupaten/kota...",
  contactInformationInputDistrictPlaceholder: "Pilih kecamatan...",
  contactInformationInputSubDistrictPlaceholder: "Pilih kelurahan...",

  accountInformationFormTitle: "Informasi Akun",
  accountInformationInputStatusLabel: "Status Akun",
  accountInformationInputPasswordLabel: "Kata Sandi",
  accountInformationInputPasswordHelper: "Pastikan kata sandi terdiri dari 8 kombinasi karakter huruf, angka, huruf kapital, dan simbol.",

  workInformationFormTitle: "Pekerjaan",
  workInformationInputExperienceLabel: "Riwayat Pekerjaan",
  workInformationInputSpecialitiesLabel: "Keahlian",
  workInformationConsultationCategoryLabel: "Bidang Keahlian",
  workInformationInputSKNumberLabel: "Nomor SK",
  workInformationInputSpecialitiesPlaceholder: "Psikodiagnostika",
  workInformationInputSKNumberPlaceholder: "2111/SKALA/REN/KLA5/2022",
  workInformationConsultationCategoryPlaceholder: "Pilih bidang keahlian...",

  workInformationAddNewExperience: "Tambah Riwayat Pekerjaan",
  workInformationEditExperienceMessage: "Edit Riwayat Pekerjaan",
  workInformationDeleteExperienceMessage: "Hapus riwayat pekerjaan",
  workInformationInputExperienceEmpty: "Belum ada data riwayat pekerjaan",
  workInformationInputSpecialitiesHelper: "Silakan ketik keahlian tenaga ahli, kemudian tekan enter untuk menambahkan keahlian baru. Contoh: Psikodiagnostika",

  workExperienceInputCompanyLabel: "Nama Perusahaan",
  workExperienceInputJobTitleLabel: "Jabatan/Pekerjaan",
  workExperienceInputStartYearLabel: "Tahun Mulai Bekerja",
  workExperienceInputEndYearLabel: "Tahun Selesai Bekerja",

  otpInputLabel: "Kode OTP Anda",
  successCreateUserMessage: "Akun berhasil dibuat! Mohon periksa email untuk aktivasi.",
  successSendOtp: "OTP berhasil dikirim!",
  successVerifyOtp: "Akun Anda telah aktif. Selamat datang!",
  failedCreateUserMessage: "Terjadi kesalahan saat membuat akun!",
  invalidOtp: "Mohon ketikkan kode OTP yang valid!",
  failedSendOtp: "Terjadi kesalahan saat mengirim OTP!",
  failedVerifyOtp: "Mohon maaf, OTP Anda salah!",
  successUpdateUser: "Data berhasil diperbarui!",
  failedUpdateUser: "Terjadi kesalahan saat memperbarui data pengguna!",

  deactivateUserModalTitle: "Nonaktifkan Akun",
  deactivateUserModalMessage: "Apakah Anda yakin ingin menonaktifkan akun ini?",
  successActivateUser: "Akun berhasil diaktifkan!",
  successDeactivateUser: "Akun berhasil dinonaktifkan!",
  failedUpdateUserStatus: "Terdapat kesalahan saat mengubah status akun. Mohon coba ulangi beberapa saat lagi.",

  successCreateExpertUserMessage: "Akun berhasil dibuat!",
  successAddWorkExperienceMessage: "Berhasil menambahkan riwayat pekerjaan!",
  successEditWorkExperienceMessage: "Data riwayat pekerjaan berhasil diperbarui",
  successDeleteWorkExperienceMessage: "Data riwayat pekerjaan berhasil dihapus",
  noWorkExperienceFoundMessage: "Mohon tambahkan riwayat pekerjaan!",
  failedAddWorkExperienceMessage: "Terjadi kesalahan saat menambahkan riwayat pekerjaan",
  failedEditWorkExperienceMessage: "Terjadi kesalahan saat memperbarui riwayat pekerjaan",
  failedDeleteWorkExperienceMessage: "Terjadi kesalahan saat menghapus riwayat pekerjaan",

  // EXPERT USER
  addExpertUser: "Tambah Tenaga Ahli",

  // ADMIN
  addAdmin: "Tambah Admin",

  // ACTIVITY HISTORY
  answeredQuestions: "pertanyaan terjawab",
  scoreInputLabel: "Skor",
  scoreInputHelper: "Berikan skor apabila menurut Anda, keterangan atau lampiran yang diberikan oleh operator sudah memenuhi kriteria yang ditentukan.",
  noteInputLabel: "Catatan",
  noteInputHelper: "Tulis tanggapan Anda terkait keterangan atau lampiran yang mungkin perlu dilengkapi oleh operator.",
  successScoreQuestionnaireAnswer: "Skor berhasil diberikan!",
  successAddQuestionnaireAnswerNote: "Catatan berhasil ditambahkan!",
  errorScoreQuestionnaireAnswer: "Mohon maaf, terjadi kesalahan saat memberikan skor!",
  errorAddQuestionnaireAnswerNote: "Mohon maaf, terjadi kesalahan saat memberikan catatan!",
  noFeedbackNote: "Belum ada evaluasi",

  // ANALYTICS
  highlightTitle: "Rekapitulasi",
  activityTitle: "Aktivitas",
  scoreTitle: "Pantauan Skor Per Klaster Tahun",
  highlightLabel: "Indeks Skor Periode Ini",
  totalQuestionnaireLabel: "Total Kuesioner Tahun",
  totalScoreLabel: "Total Skor Tahun",

  // PLACEHOLDER
  searchPlaceholder: "Cari...",
  selectPlaceholder: "Pilih...",

  // FORM VALIDATION
  validationRequired: "Mohon isi semua kolom!",
  validationDate: "Mohon isi dengan format tanggal yang benar!"
}
