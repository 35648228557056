import { useMemo, useState } from "react"

import type { ILooseObject, SORT_TYPE } from "@/constants/types"
import { TSearchRequest } from "@/constants/types/components/input"

interface UseTable {
  data: ILooseObject[],
  firstPage?: 0 | 1
}

function useTable<S> ({
  data,
  firstPage
}: UseTable) {
  const [page, setPage] = useState<number>(firstPage || 0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [params, setParams] = useState<S | any | null>(null)

  const hasParams = useMemo(() => {
    for (const key in params) {
      if (params[key] !== null) {
        return true
      }
    }
    return false
  }
  , [params])

  const memoizedCollections = useMemo(() => {
    if (data.length === 0) {
      return []
    }
    return data
  }, [data, rowsPerPage, page])

  function handleSubmitSearch (query: TSearchRequest) {
    setParams((state: typeof params) => ({ ...state, ...query }))
  }

  function handleSetFilter (value: object) {
    setParams((state: typeof params) => ({ ...state, ...value }))
    handleChangePage(null, 0)
  }

  function handleSelectSort (value: SORT_TYPE) {
    setParams((state: typeof params) => ({ ...state, sort: value }))
  }

  function handleChangePage (
    event: React.MouseEvent<HTMLButtonElement> | React.ChangeEvent<unknown> | null,
    page: number
  ) {
    setPage(page)
    setParams((state: typeof params) => ({
      ...state,
      page: firstPage === 1 ? page : page + 1
    }))
  }

  function handleChangeRowsPerPage (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const count = parseInt(event.target.value, 10)
    setRowsPerPage(count)
    setParams((state: typeof params) => ({ ...state, per_page: count }))
    handleChangePage(null, 0)
  }

  return {
    page,
    rowsPerPage,
    params,
    hasParams,
    memoizedCollections,
    setParams,
    handleSubmitSearch,
    handleSetFilter,
    handleSelectSort,
    handleChangePage,
    handleChangeRowsPerPage
  }
}

useTable.defaultProps = {
  firstPage: 0
}

export default useTable
