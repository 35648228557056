import type { TRootState } from "@/constants/types"

export const initialState: TRootState = {
  abortController: new AbortController(),
  action: null,
  fallbackMessage: "",
  isLoading: false,
  modal: {
    value: null,
    open: false,
    title: ""
  },
  toast: null
}
