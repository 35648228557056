import Cookies from "js-cookie"

type CookiesVariants = "skala_token" | "skala_user"

export function setCookies (name: CookiesVariants, value: any) {
  Cookies.set(name, value)
}

export function getCookies (name: CookiesVariants) {
  return Cookies.get(name)
}

export function removeCookies (name: CookiesVariants) {
  return Cookies.remove(name)
}

export function removeAllCookies () {
  Object.keys(Cookies.get()).forEach(function (cookieName) {
    const neededAttributes = {}
    Cookies.remove(cookieName, neededAttributes)
  })
}

export function setEncodedCookies (name: CookiesVariants, value: any) {
  const encoded = JSON.stringify(value)
  return setCookies(name, encoded)
}

export function getDecodedCookies (name: CookiesVariants) {
  const cookie = getCookies(name)
  if (cookie === undefined) {
    return null
  }
  return JSON.parse(cookie.split("%22").join(""))
}
