import { AlertColor } from "@mui/material"
import { AxiosResponse } from "axios"

import Endpoints from "@/config/endpoints"
import type { ILoginForm } from "@/constants/types"
import { t } from "@/hooks"
import Api, { createAxiosInstance } from "@/libs/api"
import { removeAllCookies, setCookies, setEncodedCookies } from "@/libs/cookies"
import { getRootStore, updateRootStore } from "@/store"

export async function login (payload: ILoginForm, callback?: (params?: any) => void): Promise<void> {
  return Api
    .post(Endpoints.login, payload)
    .then(_handleResolvedLogin)
    .then(callback)
    .catch(_handleRejectedLogin)
}

export async function logout (): Promise<void> {
  return Api
    .post(Endpoints.logout)
    .then(() => getRootStore().closeModal())
    .catch(() => _notify("error", t("failedLogout")))
    .finally(() => localLogout())
}

function localLogout () {
  removeAllCookies()
  window.location.replace("/login")
}

export async function sendOtp (payload: string) {
  return Api
    .post(Endpoints.sendOtp, {
      email: payload
    })
    .then(() => _notify("success", t("successSendOtp")))
    .catch(() => _notify("error", t("failedSendOtp")))
}

export async function verifyOtp (token: string, payload: number) {
  const customApi = createAxiosInstance({
    headers: { Authorization: "Bearer " + token }
  })
  return customApi
    .post(Endpoints.verifyOtp, {
      otp: payload
    })
    .then(response => {
      _notify("success", t("successVerifyOtp"))
      _handleResolvedVerifyOtp(token, response)
    })
}

function _handleResolvedVerifyOtp (token: string, response: AxiosResponse) {
  const { data } = response.data
  removeAllCookies()
  setCookies("skala_token", token)
  setEncodedCookies("skala_user", data)
  window.location.replace("/")
}

function _handleResolvedLogin (response: AxiosResponse): void {
  const { data } = response.data
  setCookies("skala_token", data.token)
  setEncodedCookies("skala_user", data.user)
}

function _handleRejectedLogin (errors: AxiosResponse): void {
  if (errors.data) {
    if (Object.keys(errors?.data?.message)?.length > 0) {
      if (errors.data.message.email) {
        return updateRootStore({ fallbackMessage: errors.data.message.email })
      }
    }
    return updateRootStore({ fallbackMessage: errors.data.message })
  }
  return updateRootStore({ fallbackMessage: "error" })
}

function _notify (status: AlertColor, message: string) {
  updateRootStore({
    toast: {
      status,
      message
    }
  })
}
