import { useTranslation } from "@/hooks"

function MobilePage () {
  const { t } = useTranslation()
  return (
    <div className="flex min-h-screen w-screen items-center justify-center bg-neutral-100">
      <p className="w-9/12 text-center text-xl font-light text-neutral-400 sm:text-2xl md:text-3xl">
        {t("incompatibleDeviceMessage")}
      </p>
    </div>
  )
}

export default MobilePage
