import { lazy, Suspense } from "react"

import { Navigate, useRoutes } from "react-router-dom"

import PleaseWaitIllustration from "@/assets/svg/please-wait.svg"
import ErrorBoundary from "@/components/error-boundary/error-boundary"
import { ADMIN, HUMAS, OPERATOR, SUPER_ADMIN } from "@/config/user"
import type { TRouteConfig } from "@/constants/types"
import { useProtectedRoute, useTranslation } from "@/hooks"

const SimpleLayout = lazy(() => import("@/layouts/simple-layout"))
const DashboardLayout = lazy(() => import("@/layouts/dashboard-layout"))
const NotFoundPage = lazy(() => import("@/pages/not-found-page"))
const LoginPage = lazy(() => import("@/pages/login-page"))
const OtpPage = lazy(() => import("@/pages/otp-page"))
const HomePage = lazy(() => import("@/pages/home-page"))
const ClusterPage = lazy(() => import("@/pages/cluster-list-page"))
const IndicatorListPage = lazy(() => import("@/pages/indicator-list-page"))
const AdminQuestionnaireListPage = lazy(() => import("@/pages/questionnaire-admin-list-page"))
const OperatorQuestionnaireListPage = lazy(() => import("@/pages/questionnaire-operator-list-page"))
const QuestionnaireAddPage = lazy(() => import("@/pages/questionnaire-create-page"))
const QuestionnaireDetailPage = lazy(() => import("@/pages/questionnaire-detail-page"))
const QuestionnaireAnswerPage = lazy(() => import("@/pages/questionnaire-answer-page"))
const QuestionnaireAnalyticsPage = lazy(() => import("@/pages/questionnaire-analytics-page"))
const FeedbackListPage = lazy(() => import("@/pages/feedback-list-page"))
const FeedbackDetailPage = lazy(() => import("@/pages/feedback-detail-page"))
const ExpertUserListPage = lazy(() => import("@/pages/expert-user-list-page"))
const ExpertUserCreatePage = lazy(() => import("@/pages/expert-user-create-page"))
const ExpertUserEditPage = lazy(() => import("@/pages/expert-user-edit-page"))
const AdminListPage = lazy(() => import("@/pages/admin-list-page"))
const AdminCreatePage = lazy(() => import("@/pages/admin-create-page"))
const AdminEditPage = lazy(() => import("@/pages/admin-edit-page"))
const UserListPage = lazy(() => import("@/pages/user-list-page"))
const ActivityHistoryListPage = lazy(() => import("@/pages/activity-history-list-page"))
const ActivityHistoryDetailPage = lazy(() => import("@/pages/activity-history-detail-page"))

function LazyPage ({
  element
}: { element: JSX.Element }) {
  const { t } = useTranslation()
  return (
    <Suspense
      fallback={(
        <div className="flex flex-col items-center gap-4">
          <img
            alt={t("pleaseWait")}
            src={PleaseWaitIllustration}
            width="540"
          />
          {t("pleaseWait")}
        </div>
      )}
    >
      <ErrorBoundary>
        {element}
      </ErrorBoundary>
    </Suspense>
  )
}

/**
 * path structure
 * single path: {path}
 * children without params: {path}/{children}
 * children with params: {path}/{params}/{children}
 */
export const routeConfig: TRouteConfig[] = [
  {
    element: <SimpleLayout />,
    roles: ["*", ADMIN, HUMAS, OPERATOR, SUPER_ADMIN],
    children: [
      {
        path: "*",
        roles: ["*", ADMIN, HUMAS, OPERATOR, SUPER_ADMIN],
        element: <LazyPage element={<NotFoundPage />} />
      },
      {
        index: true,
        roles: ["*"],
        element: (
          <Navigate
            to="/login"
            replace
          />
        )
      },
      {
        path: "login",
        roles: ["*"],
        element: <LazyPage element={<LoginPage />} />
      },
      {
        path: "login",
        roles: [ADMIN, HUMAS, OPERATOR, SUPER_ADMIN],
        element: (
          <Navigate
            to="/"
            replace
          />
        )
      },
      {
        path: "aktivasi/:token",
        roles: ["*", ADMIN, HUMAS, OPERATOR, SUPER_ADMIN],
        element: <LazyPage element={<OtpPage />} />
      }
    ]
  },
  {
    element: <DashboardLayout />,
    roles: [ADMIN, HUMAS, OPERATOR, SUPER_ADMIN],
    children: [
      {
        index: true,
        title: "Beranda",
        roles: [OPERATOR, HUMAS],
        element: <LazyPage element={<HomePage />} />
      },
      {
        path: "klaster",
        title: "Master Data Klaster",
        roles: [ADMIN, SUPER_ADMIN],
        element: <LazyPage element={<ClusterPage />} />
      },
      {
        path: "indikator",
        title: "Master Data Indikator",
        roles: [ADMIN, SUPER_ADMIN],
        element: <LazyPage element={<IndicatorListPage />} />
      },
      {
        path: "kuesioner",
        title: "Kuesioner",
        roles: [ADMIN, SUPER_ADMIN],
        element: <LazyPage element={<AdminQuestionnaireListPage />} />
      },
      {
        path: "kuesioner",
        title: "Kuesioner",
        roles: [OPERATOR],
        element: <LazyPage element={<OperatorQuestionnaireListPage />} />
      },
      {
        path: "riwayat-aktivitas",
        title: "Riwayat Aktivitas Pengisian Kuesioner",
        roles: [ADMIN, SUPER_ADMIN],
        element: <LazyPage element={<ActivityHistoryListPage />} />
      },
      {
        path: "aduan",
        title: "Aduan",
        roles: [ADMIN, HUMAS, SUPER_ADMIN],
        element: <LazyPage element={<FeedbackListPage />} />
      },
      {
        path: "aduan/:id/detail",
        title: "Detail Aduan",
        roles: [ADMIN, HUMAS, SUPER_ADMIN],
        element: <LazyPage element={<FeedbackDetailPage />} />
      },
      {
        path: "tenaga-ahli",
        title: "Tenaga Ahli",
        roles: [ADMIN, SUPER_ADMIN],
        element: <LazyPage element={<ExpertUserListPage />} />
      },
      {
        path: "tenaga-ahli/baru",
        title: "Tambah Tenaga Ahli",
        roles: [ADMIN, SUPER_ADMIN],
        element: <LazyPage element={<ExpertUserCreatePage />} />
      },
      {
        path: "tenaga-ahli/:id/edit",
        title: "Edit Tenaga Ahli",
        roles: [ADMIN, SUPER_ADMIN],
        element: <LazyPage element={<ExpertUserEditPage />} />
      },
      {
        path: "admin",
        title: "Admin",
        roles: [SUPER_ADMIN],
        element: <LazyPage element={<AdminListPage />} />
      },
      {
        path: "admin/baru",
        title: "Tambah Admin",
        roles: [SUPER_ADMIN],
        element: <LazyPage element={<AdminCreatePage />} />
      },
      {
        path: "admin/:id/edit",
        title: "Edit Admin",
        roles: [SUPER_ADMIN],
        element: <LazyPage element={<AdminEditPage />} />
      },
      {
        path: "pengguna",
        title: "Pengguna",
        roles: [ADMIN, SUPER_ADMIN],
        element: <LazyPage element={<UserListPage />} />
      }
    ]
  },
  {
    element: (
      <DashboardLayout
        disableBackground
        disableTitle
      />
    ),
    roles: [ADMIN, OPERATOR, SUPER_ADMIN],
    children: [
      {
        index: true,
        title: "Beranda",
        roles: [ADMIN, SUPER_ADMIN],
        element: <LazyPage element={<QuestionnaireAnalyticsPage />} />
      },
      {
        path: "kuesioner/baru",
        title: "Buat Kuesioner",
        roles: [ADMIN, SUPER_ADMIN],
        element: <LazyPage element={<QuestionnaireAddPage />} />
      },
      {
        path: "kuesioner/:id/edit",
        title: "Edit Kuesioner",
        roles: [ADMIN, SUPER_ADMIN],
        element: <LazyPage element={<QuestionnaireDetailPage />} />
      },
      {
        path: "kuesioner/:id/detail",
        title: "Detail Kuesioner",
        roles: [OPERATOR],
        element: <LazyPage element={<QuestionnaireAnswerPage />} />
      },
      {
        path: "riwayat-aktivitas/:id/tinjau",
        title: "Evaluasi Jawaban Kuesioner",
        roles: [ADMIN, SUPER_ADMIN],
        element: <LazyPage element={<ActivityHistoryDetailPage />} />
      }
    ]
  }
]

function RouterView () {
  const filteredRoutes = useProtectedRoute({
    routes: routeConfig
  })
  const routes = useRoutes(filteredRoutes)
  return (
    <Suspense>
      {routes}
    </Suspense>
  )
}

export default RouterView
