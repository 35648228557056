import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

import { t } from "@/hooks/use-translation"
import { logout } from "@/modules/auth/api/controller"
import { getRootStore, updateRootStore } from "@/store"

import { getCookies } from "./cookies"

function onRequest (config: AxiosRequestConfig): AxiosRequestConfig {
  if (!navigator.onLine) {
    updateRootStore({ toast: { status: "error", message: t("noInternetMessage") } })
  }

  const bearerToken = getCookies("skala_token")
  if (config.headers && bearerToken) {
    config.headers.Authorization = "Bearer " + bearerToken
  }

  updateRootStore({ isLoading: true })

  const controller = new AbortController()
  updateRootStore({ abortController: controller })

  return {
    ...config,
    signal: getRootStore().abortController.signal
  }
}

function onRequestError (error: AxiosError): Promise<AxiosError> {
  updateRootStore({ isLoading: false })
  return Promise.reject(error)
}

function onResponse (response: AxiosResponse): AxiosResponse {
  updateRootStore({ isLoading: false })
  return response
}

function onResponseError (error: AxiosError): Promise<AxiosError> {
  updateRootStore({ isLoading: false })

  if (error.response) {
    if (!error.response.status) {
      updateRootStore({ toast: { status: "error", message: t("noServerConnection") } })
    }
    if ([401, 403].includes(error.response.status)) {
      const bearerToken = getCookies("skala_token")
      if (bearerToken) {
        logout()
      }
    }
  }

  return Promise.reject(error.response)
}

export function applyInterceptors (axiosInstance: AxiosInstance): AxiosInstance {
  axiosInstance.interceptors.request.use(onRequest, onRequestError)
  axiosInstance.interceptors.response.use(onResponse, onResponseError)
  return axiosInstance
}

export function createAxiosInstance (config?: AxiosRequestConfig) {
  return axios.create({
    ...config,
    baseURL: import.meta.env.VITE_API_BASE_URL
  })
}

const Api = applyInterceptors(createAxiosInstance())

export default Api
