import { indonesian } from "@/constants/lang"

function useTranslation () {
  function t (key: string): string {
    return indonesian[key as keyof typeof indonesian]
  }

  return {
    t
  }
}

export default useTranslation

export const {
  t
} = useTranslation()
