import useMediaQuery from "@mui/material/useMediaQuery"

import MobilePage from "@/pages/mobile-page"
import { RouterView } from "@/routes"

function MainApp () {
  const isDeviceHasMinimumWidth = useMediaQuery("(min-width:1280px)")
  if (isDeviceHasMinimumWidth) {
    return (
      <RouterView />
    )
  }

  return <MobilePage />
}

export default MainApp
