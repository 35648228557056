/**
 * Define user roles
 */
export const SUPER_ADMIN = 1
export const ADMIN = 2
export const OPERATOR = 3
export const HUMAS = 4
export const USER = 5

export const SUPER_ADMIN_LABEL = "Super Admin"
export const ADMIN_LABEL = "Admin"
export const OPERATOR_LABEL = "Operator"
export const HUMAS_LABEL = "Humas"

type USER_ROLE_NAME_TYPE = {
  [key: number]: string
}

export const USER_ROLE_NAME: USER_ROLE_NAME_TYPE = {
  [SUPER_ADMIN]: SUPER_ADMIN_LABEL,
  [ADMIN]: ADMIN_LABEL,
  [OPERATOR]: OPERATOR_LABEL,
  [HUMAS]: HUMAS_LABEL
}

export const ADMIN_ROLES = [SUPER_ADMIN, ADMIN, OPERATOR, HUMAS]
