import * as React from "react"

import { ThemeProvider } from "@mui/material/styles"
import * as ReactDOM from "react-dom/client"
import { BrowserRouter } from "react-router-dom"

import MainApp from "@/app/main-app"
import { CUSTOM_THEME } from "@/config/themes"

import "@fontsource/roboto/300.css"
import "@fontsource/roboto/400.css"
import "@fontsource/roboto/500.css"
import "@fontsource/roboto/700.css"
import "@/assets/css/main.css"

ReactDOM.createRoot(document.getElementById("app")!).render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={CUSTOM_THEME}>
        <MainApp />
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>
)
