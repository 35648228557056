import { grey, teal } from "@mui/material/colors"
import { createTheme } from "@mui/material/styles"

import { t } from "@/hooks"

export const CUSTOM_THEME = createTheme({
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true
      },
      styleOverrides: {
        root: {
          "&.MuiButton-root": {
            textTransform: "none"
          }
        }
      }
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root:hover": {
            "backgroundColor": grey[100],
            "& > fieldset": {
              borderColor: "inherit"
            }
          },
          "& .MuiOutlinedInput-root.Mui-error:hover": {
            "backgroundColor": grey[100],
            "& > fieldset": {
              borderColor: "#d32f2f"
            }
          }
        }
      }
    },
    MuiAutocomplete: {
      defaultProps: {
        clearText: t("delete"),
        closeText: t("close"),
        loadingText: t("pleaseWait"),
        noOptionsText: t("noOptions"),
        openText: t("seeOptions")
      }
    }
  },
  palette: {
    primary: {
      main: teal[500]
    },
    secondary: {
      main: grey[800]
    },
    light: {
      main: grey[600]
    }
  },
  transitions: {
    duration: {
      shortest: 150,
      shorter: 150,
      short: 150,
      // most basic recommended timing
      standard: 150,
      // this is to be used in complex animations
      complex: 150,
      // recommended when something is entering screen
      enteringScreen: 150,
      // recommended when something is leaving screen
      leavingScreen: 50
    }
  }
})

declare module "@mui/material/styles" {
  interface Palette {
    light: Palette["primary"];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    light?: PaletteOptions["primary"];
  }
}

declare module "@mui/material/Button" {
  // eslint-disable-next-line no-unused-vars
  interface ButtonPropsColorOverrides {
    light: true;
  }
}
