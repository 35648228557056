class Endpoints {
  public static login = "/login"
  public static logout = "/logout"
  public static register = "/register"
  public static sendOtp = "/activate/otp"
  public static verifyOtp = "/verify-otp"
  public static cluster = "/clusters"
  public static indicator = "/indicators"
  public static questionnaire = "/questionnaire"
  public static questionnaireRange = "/questionnaire/range"
  public static questionnaireStatus = "/questionnaire/status"
  public static questionnaireAnswer = "/questionnaire/answer"
  public static feedbacks = "/feedbacks"
  public static users = "/users"
  public static province = "/provinces"
  public static regency = "/regencies"
  public static district = "/districts"
  public static consultations = "/consultations"
  public static consultant = "/consultant"
  public static consultantExperience = "/consultant/experience"
  public static analytics = "/analytics"
}

export default Endpoints
