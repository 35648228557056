import create from "zustand"

import type { TRootStore } from "@/constants/types"
import { initialState } from "@/store/reducer"

export const useStore = create<TRootStore>()(set => ({
  ...initialState,
  setLoading (state) {
    set(() => ({
      isLoading: state
    }))
  },
  setFallbackMessage (state) {
    set(() => ({
      fallbackMessage: state
    }))
  },
  setAction (state) {
    set(() => ({
      action: state
    }))
  },
  setToast (toastStatus, toastMessage) {
    set(() => ({
      toast: {
        status: toastStatus,
        message: toastMessage
      }
    }))
  },
  clearToast () {
    set(() => ({
      toast: null
    }))
  },
  openModal (titleState, dataState) {
    set(() => ({
      modal: {
        value: dataState ?? initialState.modal.value,
        open: true,
        title: titleState ?? initialState.modal.title
      }
    }))
  },
  closeModal () {
    set(() => ({
      modal: initialState.modal
    }))
  },
  clearRootState () {
    set(initialState)
  }
}))

export const {
  getState: getRootStore,
  setState: updateRootStore,
  subscribe: subscribeRootStore
} = useStore
