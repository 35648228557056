import { useCallback, useMemo } from "react"

import { TRouteConfig } from "@/constants/types"
import { getDecodedCookies } from "@/libs/cookies"

interface UseProtectedRoute {
  routes: TRouteConfig[]
}

function useProtectedRoute ({
  routes
}: UseProtectedRoute) {
  const user = getDecodedCookies("skala_user")
  const currentUserRole = user?.role_id ?? "*"

  const filterRoute = useCallback(function (routes: TRouteConfig[]): TRouteConfig[] {
    return routes
      .filter(route => route.roles?.includes(currentUserRole))
      .map(route => {
        if (!route.children) {
          return route as TRouteConfig
        }
        return {
          ...route,
          children: filterRoute(route.children)
        } as TRouteConfig
      })
  }, [currentUserRole])

  return useMemo(() => {
    return filterRoute(routes)
  }, [currentUserRole])
}

export default useProtectedRoute
